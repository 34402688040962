import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import Layout from "../layouts/layout";

import { HeaderTitle } from "../components/HeaderTitle";
import { HtmlContent } from "../components/HtmlContent";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";

const TesterTerms = () => (
  <Layout title={"General Terms and Conditions"}>
    <article>
      <HeaderTitle overline={"Last modified: May 14, 2019"} titleWidth={"17em"}>
        General Terms and Conditions ("Agreement")
      </HeaderTitle>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <HtmlContent
          className={cn(helperStyles.spacingMd, helperStyles.widthHtmlContent)}
        >
          <h2>1. Acceptance and Conclusion of the Agreement</h2>
          <p>
            This is an agreement (“Agreement”) between Userbrain GmbH
            (“Userbrain"), the owner and operator of www.userbrain.com and of
            the applications, software and services offered by Userbrain (as
            defined below and together as the “Platform”), and you (“you”,
            “your” or “User”), the User of the Platform.
          </p>
          <p>
            Throughout this Agreement, the words “Userbrain,” “us,” “we,” and
            “our,” refer to our company, Userbrain, as appropriate in the
            context of the use of the words.
          </p>
          <p>
            By registering as a Userbrain tester and by accessing or using the
            Platform or the Userbrain Services you agree to all terms and
            conditions of this Agreement. We reserve the right to modify or
            amend this Agreement and our Privacy Policy at any time. In the case
            of a modification or amendment we will notify you in due time. If
            you do not agree with the Terms of Service or the Privacy Policy,
            please stop using the Platform and all Userbrain Services
            immediately.
          </p>

          <h2>2. User Information and Accounts</h2>
          <p>
            Users must register or log in before accessing the Platform or the
            Userbrain Services. Your data will be recorded and disclosed as
            stated in our Privacy Policy. The accuracy of the information to be
            provided regarding your identity, including your age and country of
            residence, are of fundamental significance to Userbrain and its
            clients. All Users must provide{" "}
            <strong>truthful and accurate information</strong> when registering
            for our Platform, may only register for one account at a time, and
            must be over the age of 18.{" "}
          </p>
          <p>
            We reserve the right to verify all User data and to reject Users. We
            also reserve the right at our discretion to grant or deny accounts
            created by Users. The right to reject or delete User accounts shall
            continue to apply at any later point in time, unless another, more
            specific provision of these Terms and Conditions applies in that
            case. You shall be solely responsible for keeping all account
            information up to date and for keeping confidential your account
            information and any and all activities related to your account. You
            must not share your access to and use of your account with any third
            party. You agree to immediately notify Userbrain of any unauthorised
            use of your account or any other breach of security. Userbrain shall
            not be liable for any losses you incur as a result of someone else
            using your password or account, be it with or without your
            knowledge.
          </p>

          <h2>3. Access</h2>
          <p>
            After registration we shall grant you access to the Platform as
            permitted by us and in accordance with this Agreement, if required.
            All rights not explicitly and expressly granted vis-à-vis Users
            shall be reserved for Userbrain. If you download software to access
            our Platform, we shall grant you a limited, fully revocable,
            non-exclusive license to download a copy of our Platform onto your
            electronic device(s), as permitted by us. If you violate this
            Agreement, your access or license to use our Platform may be
            terminated at our discretion. Additionally, we may revoke your
            access or license to use our Platform if we believe that your
            actions may harm us, our business interests, or any third-party
            rights. Failure by us to revoke your access shall be considered no
            approval of misconduct or waiver of claims that may result
            therefrom.
          </p>

          <h2>4. Platform Ownership</h2>
          <p>
            The User acknowledges that the structure, organization, design, and
            code of the Platform and all related software components are
            proprietary to Userbrain and/or Userbrain's licensors and that
            Userbrain and/or its licensors retain(s) exclusive title to the
            Platform, any and all documentation, information and any and all
            other proprietary rights, intellectual property rights or copyrights
            relating to the Platform, including all modifications, copies,
            enhancements, derivatives, and other software and materials
            developed by Userbrain hereunder. The User shall not sell, copy,
            reproduce, transfer, publish, disclose, display or otherwise make
            available the Platform, access to the Platform, any parts of the
            Platform including modifications or amendments, enhancements,
            derivatives, and other software or materials developed under this
            Agreement, or any intellectual property rights and copyrights. The
            User shall neither remove any (intellectual) property rights,
            copyrights, trademark rights of any kind, or other rights from any
            part of the Platform, nor modify or otherwise edit them, including
            any and all modifications, enhancements, derivatives, and other
            software and materials developed by Userbrain. The User agrees to
            use all necessary and reasonable efforts to comply with the above
            provisions in such a way that Userbrain's rights under this
            Agreement will be maintained.
          </p>

          <h2>5. Platform and Services</h2>
          <p>
            Via the Platform, Userbrain may make services available to you,
            including but not limited to user testing services (together as the
            “Userbrain Services”). Via the Userbrain Services, Users may test,
            and provide feedback on, software companies, mobile applications,
            and websites for Userbrain clients and other third parties (each a
            “Test”). Please be aware that any Userbrain Services and the
            Platform are offered “as is” and “as available.” Additionally, the
            Userbrain Services along with any information or content available
            on our Platform, including any and all notifications, reports,
            analyses, or tools provided, are offered for information purposes
            only. You agree that any and all information on or notifications by
            the Platform may be inaccurate, unsubstantiated or possibly even
            incorrect. The Platform and Userbrain Services do not constitute
            legal, professional, medical, or healthcare advice or diagnosis, and
            may not be used for such purposes. Furthermore, Userbrain shall not
            be liable vis-à-vis Users for any third-party content, including but
            not limited to content, User Content, third-party content, Test
            content, data, information, copies, images, URL names, or anything
            else submitted by you or any third parties using the Platform
            (together as “Non-Userbrain Content”). Specifically, Userbrain shall
            not be liable for any errors or inaccuracies related to any
            Non-Userbrain Content. The User or the third party submitting such
            Non-Userbrain Content to the Platform or making it accessible there
            shall be solely responsible and liable for any and all errors. In
            addition, we shall not be liable for any damage or other
            disadvantages of whatsoever kind which result from an interruption
            or fault of the Platform or its termination that is directly or
            indirectly caused by circumstances beyond our control, including but
            not limited to causes attributable to Users, such as (without
            limitation): any kind of accident or force majeure; strikes; riots;
            attacks; real or digital attacks or assaults of whatever kind; wars;
            pandemics; any kind of acts of God such as floods, lightning,
            earthquake, wind, ice, extreme weather conditions, or any other kind
            of force majeure and act of God; any kind of technical failure;
            changes or restrictions by laws, regulations, court decisions,
            actions or directives by a government restricting or prohibiting the
            operation of or access to the Platform; etc.{" "}
          </p>

          <h2>6. Tests</h2>
          <p>
            The Users may only conduct Tests which have been authorised by the
            Platform or uploaded onto the same in order to be conducted by the
            specific User. The Users agree to provide truthful and accurate
            information before and while conducting any Tests on the Platform.
            From the information provided on the website on Tests and the work
            of testers, from actually conducting Tests, as well as from this
            explicit record and agreement Users know that they will not be given
            instructions from Userbrain as to how Tests are to be conducted
            specifically, and that they will neither be monitored in doing so
            nor employed. Of course, this shall not apply to information that
            the User definitely needs to understand the Tests and for the basic
            ability to conduct the same accordingly. The way the Tests will be
            conducted will be determined and controlled solely by you, an
            independent contractor.{" "}
            <strong>
              The User acknowledges and agrees that Userbrain does not recommend
              or endorse or is liable for any non-Userbrain materials, products,
              content, or the like or any other external content or conduct of
              third parties or third parties which gain access to the Platform
              in connection with the Tests.
            </strong>{" "}
            Please be aware that we are not required to make available any Tests
            and that we may stop offering Tests or other Userbrain Services at
            our discretion. For some Tests special requirements regarding
            authorisation and equipment may apply, which will be stated on the
            Platform. The User shall be responsible for verifying that they meet
            all authorisation and equipment requirements stated for the relevant
            Test. Userbrain reserves the right and discretion to define all
            authorisation requirements for a User and to deny any User the right
            to participate in a Test offered on the Platform. It may also do so
            subsequently, i.e., after a User has conducted a Test, if it turns
            out that a User did actually not meet the authorisation and
            equipment requirements.
          </p>

          <h2>7. Confidentiality of Tests</h2>
          <p>
            You agree that all Tests are confidential. You agree that you will
            not use, disclose or otherwise make known to third parties any
            Tests, recordings, or any associated information or the like, unless
            such information is publicly known or Userbrain has expressly
            permitted you to do so. This obligation shall apply during and, for
            an unlimited period of time, also after the end of the contract
            term. In the event of a violation of the obligation to maintain
            secrecy, the User undertakes to pay a contractual penalty in the
            agreed lump sum of USD 5000, which shall be irrespective of fault
            and independent of whether a specific damage has been caused or not.
            Such contractual penalty shall become due for payment at the time at
            which Userbrain obtains knowledge of the violation. Additional
            claims for damages or other claims of Userbrain or third parties
            shall remain unaffected thereby. The reasonableness of this
            contractual penalty is expressly recognised and shall be subject to
            no judicial right of reduction. You hereby agree to sign a separate
            non-disclosure agreement in the event that Userbrain or a client of
            Userbrain asks you to do so.
          </p>

          <h2>8. User Responsibilities</h2>
          <p>
            The User hereby expressly represents and warrants that: (1) the User
            shall provide all materials related to conducting Tests, including
            but not limited to electronic equipment, network connections, video
            recording equipment, and other devices as required to conduct Tests,
            and shall bear all other expenses that may be necessary for
            conducting Tests; (2) the User is duly authorised, provides complete
            and accurate information regarding their identity, and has the power
            to enter into this Agreement; (3) the User does not violate any
            agreements with third parties as a result of entering into this
            Agreement with Userbrain; (4) the User will in good faith abide by
            this Agreement and all other laws, rules, and directions, including
            information that is provided by Userbrain for correctly conducting
            Tests; (5) the User shall conduct the Tests professionally and
            conscientiously, which includes, without limitation, that the User
            fully discharges all tasks and fully answers the questions asked,
            that audio and video recordings are in place free from faults or
            interruptions, that the User audibly voices their thoughts during
            the Test and that they have uploaded the Test recording in its
            entirety; (6) the User shall use the Platform only for legal and
            lawful purposes.
          </p>

          <h2>9. Protection of the User's Data</h2>
          <p>
            We value your privacy and understand your data security concerns.
            Please read our Privacy Policy to inform yourself about and
            understand our data protection practices. All information we collect
            is subject to our Privacy Policy.
          </p>

          <h2>10. Use of the Platform</h2>
          <p>
            The User shall be solely responsible for their entire use of the
            Platform. You also agree to the following:
          </p>
          <ul>
            <li>
              You must not copy any part of the Platform to or distribute or
              disclose any part of the Platform in any medium, including,
              without limitation, by any automated or non-automated “scraping”;
            </li>
            <li>
              You must not attempt to interfere with or compromise the system
              integrity or security of the same, or decipher any transmissions
              to or from the servers running the Platform;
            </li>
            <li>
              You must not use any robot, spider, crawler, scraper or other
              automated means or interfaces not provided by us to access the
              Platform or to extract data;
            </li>
            <li>
              You must not use automated bots or other software to send more
              messages via our Platform than what is reasonable, goal-oriented
              and common in the case of proper use;
            </li>
            <li>
              You must not use the Platform on a computer that is used to
              operate nuclear plants, life support, or other business-critical
              applications where life or property may be at stake;
            </li>
            <li>
              You must not decompile, reverse engineer, disassemble, modify,
              rent, sell, lease, loan or distribute the Platform or parts of it,
              or create derivative works or improvements to it;
            </li>
            <li>
              You must not access our Platform in an attempt to build a similar
              or other rival product;
            </li>
            <li>You must not use the Platform unlawfully;</li>
            <li>
              You must not take any action that imposes, or at our sole
              discretion may impose, an unreasonable or disproportionately high
              load on our infrastructure;
            </li>
            <li>
              You must not collect any personal data, including account names,
              from the Platform, except where this is permitted;
            </li>
            <li>
              You must not impersonate any person or entity or misrepresent your
              affiliation with any person or entity;
            </li>
            <li>
              You must not violate or infringe other people's intellectual
              property, privacy, or other contractual rights while using our
              Platform;
            </li>
            <li>
              You must not violate any requirements, procedures, policies or
              regulations of any networks connected to Userbrain;
            </li>
            <li>
              You must not sell, lease, loan, distribute, transfer, or
              sublicense the Platform or access thereto or derive income from
              the use or provision of the Platform unless the functionality of
              our Platform so allows;
            </li>
            <li>You must not interfere with or disrupt the Platform;</li>
            <li>
              You must not violate any Austrian laws or regulations and you
              solely are responsible for such violations;
            </li>
            <li>
              You agree not to use the Platform in any way that is misleading,
              unlawful, defamatory, obscene, threatening, disparaging, or
              harassing.
            </li>
            <li>
              You agree that you will not hold Userbrain responsible for your
              use of our Platform; and
            </li>
            <li>
              You agree not to cause, or assist in, the disruption, destruction,
              manipulation, removal, deactivation, or compromising any part of
              our Platform, including the de-indexing or de-caching of any part
              of our Platform from a third-party website, such as by requesting
              its removal from a search engine.
            </li>
          </ul>
          <p>
            If you are found to be undertaking any of the aforementioned actions
            your privileges to use our Platform may be terminated or suspended
            at our discretion. If we believe that your actions may harm us or
            third parties, we may suspend or terminate your use of the Platform.
            In principle, we will provide an explanation when suspending or
            terminating your use. We reserve the right to lock or terminate any
            account at any time without giving notice or an explanation.
          </p>

          <h2>11. User Content</h2>
          <p>
            The User’s ability to submit or transmit information via the
            Platform, including but not limited to data, reviews, video content,
            written content, images, or any other information will be referred
            to as “User Content” herein. Please be aware that we are not
            required to host, accept, display, migrate, or forward your User
            Content; and we may refuse to accept or transmit any User Content.
            You agree that you are solely responsible for any User Content
            submitted and you release us from any liability associated with any
            User Content submitted. We provide security as customary in the
            industry, but we cannot guarantee the absolute security of such User
            Content. User Content found to be in violation of this Agreement or
            that we consider to be harmful to the Platform may be modified,
            edited, or removed at our discretion.
          </p>
          <p>
            When you submit User Content to us, you grant Userbrain, its
            partners, affiliates, Users, representatives, and employees a
            non-exclusive, limited, free-of-charge, revocable, world-wide,
            universal, transferable and assignable license to display,
            distribute, store, broadcast, transmit, reproduce, modify, create
            derivative works, or to use and reuse all or part of your User
            Content for the purpose of providing services associated with the
            Platform for the duration of this Agreement. You agree that this
            license allows us to share your User Content with our clients.
            Additionally, you grant Userbrain a worldwide, unlimited,
            irrevocable, free-of-charge license to use and incorporate into the
            Platform any suggestions, enhancement requests, recommendations,
            corrections or other feedback provided by you relating to the
            operation of our Platform. We reserve the right to remove, delete,
            modify, review, edit, or refuse User Content for any reason, and
            with or without notification to you.
          </p>

          <h2>12. Additional Permission for User Content</h2>
          <p>
            When submitting User Content to us, you agree that all of the
            individuals whose names, voices, photographs, likenesses, etc. which
            have been used in the User Content, have agreed to the User’s use
            thereof, and that Userbrain shall have the right to use all names,
            voices, photographs, likenesses, etc. contained in the User Content
            in connection with the exploitation, editing, use, etc. of the
            license granted under this Agreement. Furthermore, you represent and
            warrant the following: (1) The User owns all User Content provided
            or has obtained a license to use the same; (2) the User Content does
            not infringe on any copyrights, moral rights, trademark or other
            intellectual property rights and will not infringe on any rights to
            privacy or rights of publication or any other rights; (3) the User
            Content provided does not violate any Austrian or international
            laws, or any agreements with third parties; and (4) the User has an
            unrestricted right to grant to Userbrain all rights, licenses, and
            privileges granted or assigned to Userbrain under this Agreement.
          </p>

          <h2>13. Checking User Content</h2>
          <p>
            Userbrain shall have the right, but not the obligation, to check all
            User Content on the Platform at all times to verify compliance with
            this Agreement and all policies established by us. Without limiting
            the foregoing, Userbrain shall have the right, but no obligation, to
            remove User Content at its sole discretion. For example, we may
            remove User Content if we believe that User Content may harm us or
            our (business) interests. Except where required by law, we have no
            obligation to retain User Content or provide you with copies
            thereof.
          </p>

          <h2>14. Platform Availability</h2>
          <p>
            Although we try to provide continuous availability to you, we do not
            guarantee that the Platform will always be available, work, or be
            accessible at any particular time. Specifically, we do not guarantee
            any uptime or specific availability of the Platform. You agree and
            acknowledge that the Platform uses remote access and may not always
            be 100% reliable or available. We cannot guarantee that everything
            found on our Platform will be compatible with your devices and/or
            will work to the functionality desired by you or deliver the desired
            results.
          </p>

          <h2>15. Modification of the Platform</h2>
          <p>
            We reserve the right to alter, modify, update, or remove the
            Platform or parts thereof, at any time at our discretion. We may
            modify our Platform for security reasons, intellectual property,
            legal or various other reasons at our discretion, and we shall not
            be required to explain such modifications or grant you access to
            previous versions of our Platform. For example, we may provide
            updates to fix security gaps, to respond to statutory requirement,
            or for any other reasons at our discretion. Please note that this is
            a non-binding illustration of how we might exercise our rights under
            this Clause, and nothing in this section puts us under an obligation
            to take measures to update the Platform for security, legal or other
            reasons.
          </p>

          <h2>16. Non-Circumvention</h2>
          <p>
            The User agrees to use the Platform as the exclusive means of
            communication by and between you and all Userbrain clients. You
            shall not be allowed to attempt to provide any Userbrain clients
            with any of your contact details and you agree not to attempt to
            contact such Userbrain clients via social media or any other means
            of communication. This non-circumvention clause does not preclude
            you from using or purchasing any products or services from Userbrain
            clients. You hereby give Userbrain permission to review
            communications made between you and any of our clients on the
            Platform to verify and enforce compliance with this provision. Where
            you breach this provision, we may withhold any and all payments and
            may terminate your account at our discretion.
          </p>

          <h2>17. Payments/Forfeiture</h2>
          <p>
            Once a User has completed a Test, the User shall be required to
            upload such Test to the Platform. Userbrain shall review the User
            Content submitted to ensure that the Test has been satisfactorily
            completed. Please be aware that approval of any completed Tests is
            at Userbrain’s sole discretion, and any completed Test may be
            rejected at Userbrain’s sole discretion. Approval may be based on a
            number of factors, including but not limited to, recording quality,
            information submission, failure to follow test instructions, and
            other criteria defined by Userbrain. Upon approval Userbrain shall
            issue a credit note for the User’s account reduced by any service
            charges, processing charges, taxes or other deductions (“Userbrain
            Fee”) to the User. The amount credited for any completed Test may be
            viewed on the Platform or the User’s account dashboard. Userbrain
            reserves the right to modify or adapt credits for completed Tests or
            Userbrain Fees at any time. If Userbrain changes a Userbrain Fee
            Userbrain shall contact and notify the User before any such increase
            in the Userbrain Fee is initiated. In the event of a dispute between
            the User and Userbrain, the User agrees that Userbrain may withhold
            all funds until such dispute is resolved. In order for the User to
            withdraw payments, Userbrain may require a minimum threshold to be
            reached within a User’s account prior to a payout and registered
            data, including but not limited to name and address, to be checked
            for plausibility. Where a payout request is made, such a payout may
            take time to process but will generally be credited to the User’s
            account within one week.
          </p>
          <p>
            For as long as the User does not request that the fee for concluded
            tests be paid out to them, it will be entered on the Platform or the
            User's account dashboard as a "credit balance". The User may view
            his or her credit balance and request a payout of the same at any
            time.
          </p>
          <p>
            <strong>
              From the time a fee is credited to the User's account dashboard as
              a credit balance, the User will have{" "}
              <span style={{ textDecoration: "underline" }}>
                one (1) year's time
              </span>{" "}
              to request that the credit balance be paid out to them.{" "}
              <span style={{ textDecoration: "underline" }}>
                Thereafter the credit balance shall be forfeited irrevocably.
              </span>
            </strong>
          </p>
          <p>
            <strong>
              In case the User requests that a credit balance be paid out more
              than one year after it was initially credited to their account,
              the credit balance, or rather the claim to a payout of the fee,
              shall be forfeited.
            </strong>
          </p>
          <p>
            <strong>
              Userbrain will inform the User of the possibility of a payout and
              the otherwise imminent forfeiture via email and/or text both six
              (6) months and fourteen (14) days prior to the imminent
              forfeiture. If no such information is given, Userbrain shall not
              be able to claim this provision on forfeiture. Userbrain shall be
              free to claim the statutory periods of limitation and forfeiture
              nonetheless.
            </strong>
          </p>

          <h2>18. Taxes</h2>
          <p>
            In order for Userbrain to comply with tax laws, Users may be
            required to submit the tax records requested by Userbrain. The User
            agrees to comply with all requests to submit tax records, as
            requested by Userbrain, and shall reasonably assist Userbrain with
            any requests related to its tax compliance. The User knows and
            declares that they are solely responsible for any tax issues and
            actions relating to their activities, including but not limited to
            the calculation and payment of the taxes, fees, charges, etc.
            payable by them.
          </p>

          <h2>19. Account Lock/Termination of the Agreement</h2>
          <p>
            For important reasons, Userbrain may place a temporary or a
            permanent lock (early termination of the Agreement for important
            reason) (“Lock”) on a User’s account.
          </p>
          <p>
            The reasons why we may place a temporary Lock on a User’s account
            include the following: (1) we have reason to believe that you or
            your actions have violated this Agreement, may harm our business,
            are deceptive, misleading, unlawful, or have harmed a third party or
            interfere with a third party's contractual right; (2) at the request
            of our payment processors; or (3) if required to comply with a court
            order, a subpoena, a judicial order, an interim injunction, or as
            otherwise required by applicable laws or regulations.
          </p>
          <p>
            Any breach on the part of the User of their material obligations
            under this Agreement (in particular Clauses 2 to 5, 6 to 9 and 11)
            constitutes an important reason that entitles us to place a
            permanent Lock on the account and to immediately dissolve the
            contract, without us waiving our right to claim damages or assert
            any other claims vis-à-vis the User. Such a violation shall lead to
            a loss of the User's claim to payment (Clause 18). In the case of
            termination, we will endeavour to issue a statement to you in due
            time; we are, however, not obliged to do so.
          </p>
          <p>
            Userbrain shall decide on a case-to-case basis depending on the
            severity of the violation and the reasonableness of continuing the
            contractual relationship whether the Lock will be temporary or
            whether it will be permanent and the contract will be dissolved. In
            any case the User shall continue to be required to comply with those
            terms and conditions in the compliance with which we will have a
            legitimate interest also after the end of the contract term (in
            particular the obligation to maintain secrecy defined in Clause 8).
            If you have questions about a Lock we may have placed on your
            account, or if you need information about how to resolve the Lock,
            please do not hesitate to contact us.
          </p>

          <h2>20. Inactivity</h2>
          <p>
            If a User has not accessed their account for an extended period of
            time, Userbrain may suspend, deactivate, or otherwise archive the
            User’s account (“Inactive Account”). If the User wishes to
            reactivate an Inactive Account, they must contact Userbrain at{" "}
            <a href={"mailto:tester@userbrain.com"}>tester@userbrain.com</a>.
            Where a User has an Inactive Account, Userbrain may maintain the
            account at its discretion. However, any Inactive Accounts may be
            removed or deleted by Userbrain at its sole discretion. Where the
            User has an Inactive Account, Userbrain shall not be liable
            vis-à-vis the User for any loss of materials from such an Inactive
            Account and shall have no obligation to maintain an Inactive
            Account.
          </p>

          <h2>21. Intellectual Property</h2>
          <p>
            The name “Userbrain”, the Userbrain Platform along with the design
            of the Userbrain Platform and all texts, writings, images,
            templates, scripts, graphics, interactive features, and all
            trademarks or logos contained therein ("Marks") are owned by or
            licensed to Userbrain, subject to copyright and other industrial
            property rights under Austrian and foreign laws and international
            conventions. Userbrain reserves all rights to the Platform not
            expressly granted to Users. You agree to not use, copy, or
            distribute anything contained within the Platform, unless we have
            given our express written permission.
          </p>

          <h2>22. Submission of Ideas</h2>
          <p>
            Userbrain or any of its employees shall not accept or consider
            submissions of unsolicited ideas, including but not limited to ideas
            relating to processes, technologies, product enhancements, or
            product names. Please do not submit any unsolicited ideas, content,
            graphics, suggestions, or other works (“Submissions”) in any form to
            Userbrain. The purpose of this Clause is to avoid potential
            misunderstandings or disputes where Userbrain’s products would be
            similar to ideas you submitted to Userbrain. If you send us such
            content despite our request not to do so, the following shall apply:
            (1) Userbrain will automatically become the sole owner of your
            Submissions and their content including all other rights (i.e. the
            submitter loses any and all rights: they are transferred to
            Userbrain in their entirety) without any compensation for you; (2)
            Userbrain may use or pass on the Submissions and their content for
            any purpose and in any way; (3) there is no obligation for Userbrain
            to review the Submissions; and (4) there is no obligation to treat
            any Submissions as confidential.
          </p>

          <h2>23. Disclaimer</h2>
          <p>
            <strong>
              The Platform and all Userbrain Services shall be provided on an
              "as is", "as available" and "including all faults" basis. To the
              fullest extent permitted by law, neither Userbrain nor any of our
              employees, managers, directors or representatives shall make any
              representations, warranties, or recommendations of any kind
              whatsoever, regarding, without limitation: (1) the Platform or any
              Userbrain Services; (2) any Information or Content provided on the
              Platform; (3) the safety or stability of the Userbrain Services or
              the Platform; (4) the safety in connection with the transmission
              of information to Userbrain or third parties. In addition, we
              disclaim any warranties or liabilities, express or implied,
              including but not limited to the fitness for a particular purpose,
              user definition, undisturbed availability, non-infringement of
              rights of third parties, system integration, and freedom from
              computer viruses. Userbrain makes no representations or warranties
              that the Platform will be error-free or uninterrupted; that
              defects will be remedied; or that the Platform or the server that
              makes the Platform available is free from any harmful components.
              Userbrain makes no representations or warranties that the
              information (including any instructions or third-party
              information) on the Platform is accurate, complete, or useful.
              Userbrain assumes no warranty that your use of the Platform is
              lawful in any particular jurisdiction, and Userbrain specifically
              excludes any liability resulting therefrom. Userbrain shall assume
              no warranty or liability for the accuracy, completeness,
              up-to-dateness, or usefulness, etc.
            </strong>
          </p>
          <p>
            <strong>
              In no case shall Userbrain, any of its employees, managers,
              directors or representatives be liable vis-à-vis you for direct or
              indirect damage or incidental and consequential damage or other
              disadvantages of any kind, whether by contract or law, or in the
              case that Userbrain is informed about the fact that such damage
              may occur. The above limitation of liability shall, to the extent
              permitted by law, apply in the competent jurisdiction. In some
              jurisdictions the limitation or exclusion of liability for certain
              damage is not admissible. To the extent that this is not
              permitted, we shall not exclude liability for the following in
              those jurisdictions: (1) death and physical injury caused by
              Userbrain or any of its employees, managers, directors or
              representatives; (2) wilful damage; (3) any liability the
              exclusion of which is not in conformity with the law of the
              applicable jurisdiction neither currently nor in the future.
              However, any admissible exclusions shall remain unaffected by the
              inadmissibility of specific exclusions; thus, if only specific
              exclusions are inadmissible, any admissible exclusions of
              liability shall be maintained. Even in the case of inadmissibility
              of an exclusion of liability, Userbrain stipulates a limitation of
              liability to the effect that Userbrain's liability shall not
              exceed the amounts Userbrain paid as a fee to the (harmed)
              tester/User in the preceding six (6) months.
            </strong>
          </p>

          <h2>24. Indemnity</h2>
          <p>
            You agree to indemnify and hold harmless Userbrain, its employees,
            managers, directors, affiliates, and representatives, from and
            against any and all claims, damage, obligations, losses,
            liabilities, costs or debt, and expenses (including but not limited
            to cost of proceedings and attorney's fees) arising from any and all
            breaches of contract and/or unlawful actions in connection with this
            Agreement, in particular those that occur due to{" "}
          </p>
          <ul>
            <li>
              your use of and access to the Userbrain Platform and the Userbrain
              Services;
            </li>
            <li>
              your violation of any provision of this Agreement, in particular
              the obligation to maintain secrecy; or
            </li>
            <li>
              your violation of any third-party rights, including without
              limitation any copyright, proprietary, or contractual right.
            </li>
          </ul>
          <p>
            This defence and indemnification clause will survive the term of
            this Agreement and the use of the Userbrain Platform. You also agree
            that at our request you have an obligation to defend us against such
            claims at your cost and risk, and in such cases, we may require you
            to pay for our attorney. This obligation also extends to payments
            for any and all court costs, fees, fines, payment obligations in
            connection with court or official decisions, and any other expenses.
            In the event of a claim such as the one described in this Clause, we
            may choose to settle with the party/parties asserting the claim and
            you shall be liable for the consequences and costs resulting
            therefrom as if we had initiated legal proceedings.
          </p>

          <h2>25. Copyrights</h2>
          <p>
            We take copyright infringement very seriously. If you believe that
            any of your content has been infringed upon, please send us a
            message which contains:
          </p>
          <ul>
            <li>your name,</li>
            <li>
              the name of the party whose copyright has been infringed, if
              different from your name,
            </li>
            <li>
              the name and description of the work that is being infringed,
            </li>
            <li>the location of the infringing copy on our Platform,</li>
            <li>
              a statement that you believe, in good faith, that the use of the
              copyrighted work described above has not been authorised by the
              copyright owner (or a third party who is legally entitled to do so
              on behalf of the copyright owner) or otherwise permitted, and
            </li>
            <li>
              an affidavit that the information contained in this notification
              is accurate and that you are the copyright owner or have an
              exclusive statutory right to institute infringement proceedings
              with respect to its use. You must sign such notification and send
              it to our Copyright Agent: Copyright-Agent of Userbrain,{" "}
              <a href={"mailto:tester@userbrain.com"}>tester@userbrain.com</a>
            </li>
          </ul>

          <h3>Counterstatement</h3>
          <p>
            In the event that you receive a notification from Userbrain stating
            that content posted by you has been protected by copyright, you may
            respond by filing a counterstatement pursuant to the DMCA. Your
            counterstatement must contain the following:
          </p>
          <ul>
            <li>
              your name, address, email address and physical or electronic
              signature,
            </li>
            <li>the notification reference number (if applicable),</li>
            <li>
              identification of the material and its location before it was
              removed,
            </li>
            <li>
              an affidavit that the material was removed by mistake or
              misidentification,
            </li>
            <li>
              your consent to the jurisdiction of a federal court in the circuit
              where you live (if you are in the U.S.), or your consent to the
              jurisdiction of a federal court in the circuit where your vendor
              is located (if you are not in the U.S.)),
            </li>
            <li>
              your consent to accept service of process from the party who
              submitted the deactivation notification.
            </li>
          </ul>
          <p>
            Please be aware that we are not allowed to take any action regarding
            your counterstatement unless your notification strictly complies
            with the foregoing requirements. Please send this counterstatement
            in accordance with the deactivation request instructions above.
          </p>

          <h2>26. Choice of Law</h2>
          <p>
            This Agreement shall be governed by Austrian law. The offer and
            acceptance of this agreement shall be deemed to have occurred in
            Austria.
          </p>

          <h2>27. Place of Jurisdiction</h2>
          <p>
            Any disputes arising out of or in connection with this Agreement,
            including the issue of valid conclusion, existence or non-existence,
            and any avoidance or challenge of this Agreement, shall be
            exclusively settled by the court in Graz, Austria, having
            jurisdiction over the subject matter.
          </p>

          <h2>28. Independent Contractor Status</h2>
          <p>
            This Agreement shall not be construed to create any association,
            partnership, joint venture, employee, staff or agency relationship
            between the User and Userbrain. The User shall act on their own
            responsibility. The User shall not be bound by any instructions
            regarding the rendering of their work, in particular when it comes
            to working hours, place of work, work-related behaviour, or personal
            obligation to work. Where they can be represented by suitable third
            parties, they shall notify us thereof and assign any obligations; in
            that case, they shall remain responsible for proper performance and
            indemnify and hold harmless Userbrain. The User shall meet the
            requirements for rendering the work that might be necessary
            according to law applicable at their habitual residence or the
            registered office of their company. The User shall be solely
            responsible for the payment of taxes on the income in connection
            with this Agreement and social security contributions as per the
            law. In this regard, the User shall indemnify and hold harmless
            Userbrain. The User shall not be entitled (and shall not pretend to
            be authorised) to put Userbrain under any obligation or bind it in
            any way, and the User shall not enter into any agreements or make
            any representations in the name of Userbrain without the prior
            written approval from Userbrain. The User shall be responsible for
            all claims, receivables, fines, legal actions, etc. related to this
            Clause and shall indemnify and hold harmless Userbrain, including
            for any claims brought by the User or a third party with respect to
            taxes or contributions, including interest, fines, and any other
            receivables.
          </p>

          <h2>29. Severability Clause</h2>
          <p>
            In the event that a provision of this Agreement is found to be
            unlawful, conflicting with another provision of the Agreement, or
            otherwise unenforceable, the Agreement will remain in force as if it
            had been entered into without that unenforceable provision being
            part of it.
          </p>
          <p>
            If two or more provisions of this Agreement or any other agreement
            you may have concluded with Userbrain are deemed to be in conflict
            with one another in terms of function, Userbrain shall have the sole
            right to elect which provision will remain in force.
          </p>

          <h2>30. No Waiver of Right to Enforcement/Through Silence</h2>
          <p>
            We reserve all rights to which we are entitled under this Agreement
            or any provisions of any applicable laws. Non-enforcement of any
            particular statutory provision or of provisions of this Agreement
            shall not be construed as Userbrain's waiver of the right to enforce
            that same provision under the same or different circumstances at any
            time in the future. The same applies to silence/inaction on the part
            of Userbrain, which shall not be construed as consent or a waiver of
            rights.
          </p>

          <h2>31. Transfer of Rights/Obligations</h2>
          <p>
            You shall not assign or transfer your rights and/or obligations
            under this Agreement to any other person without our prior written
            consent unless otherwise and more specifically provided in this
            Agreement. We may assign or transfer our rights and/or obligations
            under this Agreement to another person at our discretion.
          </p>

          <h2>32. Termination by the User</h2>
          <p>
            You may terminate your account at any time via your Userbrain
            dashboard or by contacting us at{" "}
            <a href={"mailto:tester@userbrain.com"}>tester@userbrain.com</a>.{" "}
            <strong>
              Please be aware that upon termination of your account, access to
              parts of our Platform will be disabled immediately and any User
              Content will become immediately inaccessible.
            </strong>{" "}
            Upon termination, the Agreement shall be dissolved with immediate
            effect.{" "}
            <strong>
              By their termination, the User represents that they have waived
              their rights or claims vis-à-vis Userbrain.
            </strong>{" "}
            Even after termination of the Agreement, the User shall continue to
            be obliged to fully comply with all parts of this Agreement which
            can be reasonably taken to survive the validity of the Agreement and
            shall be bound by these terms and conditions, including but not
            limited to limitations of liability, payments, obligation to
            maintain secrecy and indemnity.
          </p>

          <h2>33. Modifications of or Amendments to the Agreement</h2>
          <p>
            Together with the Privacy Policy provided by Userbrain, this
            Agreement constitutes the entire and exclusive agreement between the
            Parties with regard to the subject matter contained therein and
            replaces any previous or concurrent written or oral agreements or
            agreements regarding its subject matter. Any modification of or
            amendment to a provision of this Agreement requested by the User
            shall be effective only if it is made in writing and signed by a
            duly authorised representative of each Party, i.e., if it has been
            agreed upon with Userbrain explicitly and in writing and has been
            duly signed. Where this Agreement is in conflict with other
            documentation provided on our website (except for the Privacy Policy
            and the Cookie Policy), this Agreement shall prevail.
          </p>
          <p>
            We may modify or amend this Agreement at any time. If we modify or
            amend this Agreement, we will update this page and state the date of
            the last modification or amendment. Prior to the modification or
            amendment, we will inform you about the same by email and about your
            response options and their consequences. The modification or
            amendment will enter into force 30 days after the notification of
            the modification or amendment has been sent, unless a written
            objection is made before the end of that period, which will lead to
            termination of the Agreement. Thus, unless you object in due time
            and in the required form, you agree to the modification or
            amendment. If you object in due time and in the required form, the
            objection will be considered notice of termination (Clause 34).
          </p>

          <h2>34. Electronic Communications</h2>
          <p>
            You and Userbrain shall communicate electronically, whether you
            visit the Platform or send emails to Userbrain (
            <a href={"mailto:tester@userbrain.com"}>tester@userbrain.com</a>),
            or whether Userbrain posts communications on the Platform or
            communicates with you via mobile notifications or email. For
            contractual purposes, you (1) agree to receive communications from
            Userbrain in electronic form; and (2) agree that all terms,
            conditions, agreements, communications, disclosures, and other
            notifications that Userbrain provides to you electronically satisfy
            all legal requirements that such communications would satisfy if
            they were in writing. The foregoing shall not affect your statutory
            rights.
          </p>

          <h2>35. Platform and Communications</h2>
          <p>
            Should you have any questions or issues, or if you are having
            trouble accessing or using the Platform, please do not hesitate to
            contact us through the channels provided by Userbrain. You hereby
            agree that you will solely use channels approved by the Platform or
            Userbrain for all communications related to your use of the Platform
            or issues with this Agreement. Please contact us at{" "}
            <a href={"mailto:tester@userbrain.com"}>tester@userbrain.com</a> or
            at
          </p>
          <p>
            Userbrain GmbH
            <br />
            Frauengasse 7<br />
            8010 Graz, Austria
          </p>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default TesterTerms;
